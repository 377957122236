.log-container {
    margin-top: 20px;
    text-align: left;

    .log {
        font-family: "Courier New", Courier, monospace;
        font-size: 15px;
        background-color: black;
        color: #d3d7cf;
        height: calc(100vh - 20em);
        overflow: scroll;
    }

    .red {
        color: #ef2929;
    }

    .green {
        color: #8ae234;
    }

    .yellow {
        color: yellow;
    }
}
