.firmware-component {
    .card {
        margin-bottom: 25px;
        margin-top: 25px;
    }

    .svg-inline--fa {
        margin-right: 10px;
    }
}
