@import "~bootstrap/scss/bootstrap";

.container {
    margin: 0 auto; /*make it centered*/
}

body {
    padding-bottom: 80px;
}

@include media-breakpoint-between(sm, lg) {
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 100%;
    }
}
