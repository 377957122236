@import "../../styles.scss";

/* common */
.ribbon {
    width: 100px;
    height: 100%;
    overflow: hidden;
    position: absolute;
}
.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
}
.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 4px 0;

    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    font-family: sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.ribbon.danger span {
    background-color: $danger;
}

.ribbon.success span {
    background-color: $warning;
}

/* top left*/
.ribbon-top-left {
    top: 0px;
    left: 0px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}
.ribbon-top-left::before {
    top: 0;
    right: 0;
}
.ribbon-top-left::after {
    bottom: 0;
    left: 0;
}
.ribbon-top-left span {
    right: -40px;
    top: 10px;
    transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
    top: -1px;
    right: -1px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
}
.ribbon-top-right::before {
    top: 0;
    left: 0;
}
.ribbon-top-right::after {
    bottom: 0;
    right: 0;
}
.ribbon-top-right span {
    left: -42px;
    top: 18px;
    transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
    bottom: -10px;
    left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.ribbon-bottom-left::before {
    bottom: 0;
    right: 0;
}
.ribbon-bottom-left::after {
    top: 0;
    left: 0;
}
.ribbon-bottom-left span {
    right: -25px;
    bottom: 30px;
    transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
    bottom: -10px;
    right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
    border-bottom-color: transparent;
    border-right-color: transparent;
}
.ribbon-bottom-right::before {
    bottom: 0;
    left: 0;
}
.ribbon-bottom-right::after {
    top: 0;
    right: 0;
}
.ribbon-bottom-right span {
    left: -25px;
    bottom: 30px;
    transform: rotate(-225deg);
}
