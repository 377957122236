.markdown {
    h1 {
        font-size: 1.8em;
    }

    h2 {
        font-size: 1.5em;
    }

    h3 {
        font-size: 1.2em;
    }

    h4,
    h5,
    h6 {
        font-size: 1em;
    }
}
