.footer {
    background-color: rgb(var(--bs-light-rgb));
    border-top: 1px solid var(--bs-border-color-translucent);
    padding: 0 0 10px 10px;
    font-size: 16px;
    width: 100%;
    position: fixed;
    bottom: 0;

    ul {
        list-style: none;
    }

    li {
        margin-left: 10px;
        margin-right: 10px;
        display: inline-block;
        align-items: center;
    }

    a {
        color: gray;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
}
